import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import BlogSingle from '../pages/Blogs/BlogSingle';
// import Blogs from '../pages/Blogs/Blogs';
// import Login from '../pages/Login';
// import Portfolio from '../pages/Portfolio/Portfolio';
// import Price from '../pages/Price';
// import Service from '../pages/Service';
// import SignUp from '../pages/Signup';
// import Team from '../pages/Team';
// import Testimonial from '../pages/Testimonial';
// importing all the themes
// import HomeCorporate from '../themes/index5/HomeCorporate';
// import SinglePortfolio from '../pages/Portfolio/SinglePortfolio';
// import HomeMedical from '../themes/index7/HomeMedical';
// import HomeEducation from '../themes/index8/HomeEducation';
// import HomeSecurity from '../themes/index10/HomeSecurity';
import Contact from '../themes/Contact/Contact';
import Company from '../themes/About/Company';
import ApoypheAI from '../themes/ai/ApoypheAI';
import NotFoundPage from '../themes/NotFoundPage';
import ScrollToTop from '../components/Others/ScrollToTop';


import HomeApoyphe from '../themes/home/HomeApoyphe';
import CloudServices from '../themes/cloud_services/CloudServices';
import CloudOperations from '../themes/cloud_operations/CloudOperations';
import ManagedServices from '../themes/managed_services/ManagedServices';
import SoftwareServices from '../themes/software_services/SoftwareServices';
import Industries from '../themes/industries/Industries';
import HomeEducation from "../themes/CaseStudies/HomeEducation";
import CloudServicesS1 from "../components/Services/CloudServicesS1"


const Routes = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={HomeApoyphe} />
          <Route exact path='/cloud-operation' component={CloudOperations} />
          <Route exact path='/cloud-services' component={CloudServices} />
          <Route exact path='/managed-services' component={ManagedServices} />
          <Route exact path='/software-services' component={SoftwareServices} />
          <Route exact path='/industries' component={Industries} />
          <Route exact path='/apoyphe-ai' component={ApoypheAI} />
          <Route exact path='/about-us' component={Company} />
          <Route exact path='/contact-us' component={Contact} />
          <Route exact path='/case-studies' component={HomeEducation} />
          <Route exact path='/CloudServicesS1 ' component={CloudServicesS1} />
          <Route component={NotFoundPage} />
          {/* <Route exact path='/service' component={Service} /> */}
          {/* <Route exact path='/home-medical' component={HomeMedical} /> */}
          {/* <Route exact path='/home-education' component={HomeEducation} /> */}
          {/* <Route exact path='/home-security' component={HomeSecurity} /> */}
          {/* <Route exact path='/price' component={Price} /> */}
          {/* <Route exact path='/team' component={Team} /> */}
          {/* <Route exact path='/testimonial' component={Testimonial} /> */}
          {/* <Route exact path='/portfolio' component={Portfolio} /> */}
          {/* <Route exact path='/portfolio-single' component={SinglePortfolio} /> */}
          {/* <Route exact path='/login' component={Login} /> */}
          {/* <Route exact path='/signup' component={SignUp} /> */}
          {/* <Route exact path='/blogs' component={Blogs} /> */}
          {/* <Route exact path='/blog-single' component={BlogSingle} /> */}
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
