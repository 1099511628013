import React from "react";
import { Link } from "react-router-dom";
// import  twitterlogo from "../media/logo/twitter.png";

const FooterOne = ({ classOption, dark }) => {
  return (
    <>
      <footer
        id="footer"
        className={`${classOption} ${dark ? "footer-dark" : ""}`}
      >
        <div className="container">
          <div className="footer-widget-wrapper">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div
                  id="ultraland_widget_about-3"
                  className="widget ultraland_widget about-widget_wrapper  wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  {dark ? (
                    <img
                      src="assets/img/logo.png"
                      className="footer-logo"
                      alt="footer logo"
                    />
                  ) : (
                    <img
                      src="assets/img/logo.png"
                      className="footer-logo"
                      alt="footer logo"
                    />
                  )}

                  <p className="about_text"><strong>Fueling Immense Innovations</strong></p>
                  <p>
                    <strong>Address: </strong> 4th Floor, Ayyappa Society, 467,
                    Gouri Shankara Nilayam, Madhapur, Telangana 500081
                  </p>
                  {/* <Link to='/signup' className='tt__btn'>
                    Sign up Free{' '}
                  </Link> */}
                </div>
              </div>

              <div className="col-md-2 col-sm-6">
                <div
                  className="widget widget-footer-menu wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <h3 className="widget-title">Services</h3>

                  <ul className="footer-menu">
                    <li>
                      <Link to="/cloud-services">Cloud Services</Link>
                    </li>
                    <li>
                      <Link to="/cloud-operation">Cloud Operation </Link>
                    </li>
                    <li>
                      <Link to="/managed-services">Managed Services</Link>
                    </li>
                    <li>
                      <Link to="/software-services" style={{ pointerEvents: 'none' }}>Software Services</Link>
                    </li>
                    <li>
                      <Link to="/contact"></Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div
                  className="widget widget-footer-menu wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <h3 className="widget-title">Company</h3>

                  <ul className="footer-menu">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/industries" style={{ pointerEvents: 'none' }}>Industries</Link>
                    </li>
                    <li>
                      <Link to="/apoyphe-ai" style={{ pointerEvents: 'none' }}>Artificial Intelligence</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    
                    <li>
                      <Link to='/Case-Studies' style={{ pointerEvents: 'none' }}>Case Studies</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div
                  className="widget ultraland-contact-widget wow fadeInUp"
                  data-wow-delay="0.7s"
                >
                  <h3 className="widget-title">Contact</h3>
                  <ul className="footer-contact-info">
                    <li className="phone">
                      <i className="feather-phone"></i>+91 40357 19525
                    </li>
                    <li className="email">
                      <i className="feather-mail"></i> info@apoyphe.com
                    </li>
                  </ul>
                  <h4 className="social-title">Follow Us</h4>
                  <ul className="footer-social-link">
                    <li>
                      <a href="https://in.linkedin.com/company/apoyphe">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/apoyphe">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.x.com/apoyphe">
                        {/* <i class="fa-brands fa-x-twitter"></i> */}
                        <img src="media/logo/twitter.png" alt="Twitter X Logo" style={{ width: '15px', height: '15px' }} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-between flex-column flex-md-row  site-info wow fadeIn"
            data-wow-delay="1.1s"
          >
            <p className="copy-right">© 2024 Apoyphe, All Rights Reserved.</p>

            <p className="copy-right pt-2 pt-md-0">
              Privacy Policy | Terms & Services
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
