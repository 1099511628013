import React from 'react';
// import BlogEdu from '../../components/Blogs/BlogEdu';
// import CallToActionTwo from '../../components/CallToAction/CallToActionTwo';
import NavRightOne from '../../components/NavRight/NavRightOne';
import Courses from '../../components/Courses/Courses';
import Events from '../../components/Events/Events';
// import Category from '../../components/Features/Category';
// import NavRight from '../../components/NavRight/NavRight';
// import ImageContentSeven from '../../components/ImageContent/ImageContentSeven';
import PageMeta from '../../components/PageMeta';
// import Teachers from '../../components/Team/Teachers';
// import TestimonialSix from '../../components/Testimonial/TestimonialSix';
import Layout from '../../layout';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import HeroEight from './HeroEight';

const HomeEducation = () => {
  return (
    <Layout>
      <PageMeta title='Case Studies - Apoyphe' />
      <Navbar logoDark navRight={<NavRightOne />} />
      <div id='main_content'>
        <HeroEight />
        {/* <Category /> */}
        <Courses />
        <Events />
        {/* <ImageContentSeven /> */}
        {/* <Teachers /> */}
        {/* <TestimonialSix /> */}
        {/* <BlogEdu /> */}
        {/* <CallToActionTwo /> */}
        <FooterOne />
      </div>
    </Layout>
  );
};

export default HomeEducation;
